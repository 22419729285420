export interface NodeThemeType {
    name: string;
    size: number;
    color: string;
    badgeimage?: string;
    labelcolor: string;
    strokecolor: string;
    strokewidth: number;
    opacity: number;
    status: string;
}

export interface EdgeThemeType {
    source: string;
    target: string;
    color: string;
    animated: boolean;
    dasharray: string;
    edgewidth: number;
}

export interface NodeContentType {
    questlines: number[];
    scene_folder: string;
    node_scene_text: JSON;
}

export interface LatticeResponse {
    nodedata: Record<number, NodeThemeType>;
    edgedata: Record<`edge${string}-${string}`, EdgeThemeType>;
    nodecontent: Record<number, NodeContentType>;
    unavailable_nodedata: Record<number, NodeThemeType>;
    unavailable_edgedata: Record<`edge${string}-${string}`, EdgeThemeType>;
}

export interface LayoutType {
    nodes: Record<number, LayoutNodeType>;
}

export interface LayoutNodeType {
    x: number;
    y: number;
    fixed: boolean;
}

//
// Node and Edge Themes
//

export const NODE_THEMES = {
    QUEST: {
        color: "#FFFFFF",
        labelcolor: "#cdcbe8",
        strokecolor: "#FFFFFF",
        strokewidth: 5,
        badgeimage:
            "https://arkstatic-e7cra2b9fzcectfs.z02.azurefd.net/sensei-assets/websrc/General/UIElements/new.gif",
        size: 28,
        opacity: 1,
        status: "available"
    },
    AVAILABLE: {
        color: "#cdcbe8",
        labelcolor: "#cdcbe8",
        strokecolor: "#cdcbe8",
        strokewidth: 3,
        size: 28,
        opacity: 1,
        status: "available"
    },
    COMPLETED: {
        color: "#2fdf75",
        labelcolor: "#2fdf75",
        strokecolor: "#2fdf75",
        strokewidth: 5,
        badgeimage:
            "https://arkstatic-e7cra2b9fzcectfs.z02.azurefd.net/sensei-assets/websrc/General/UIElements/complete.gif",
        size: 28,
        opacity: 1,
        status: "completed"
    },
    UNAVAILABLE: {
        color: "#cdcbe8",
        labelcolor: "#cdcbe8",
        strokecolor: "#cdcbe8",
        strokewidth: 3,
        size: 28,
        opacity: 1,
        status: "unavailable"
    },
    INTRO: {
        color: "#cdcbe8",
        labelcolor: "#cdcbe8",
        strokecolor: "#cdcbe8",
        strokewidth: 5,
        size: 32,
        opacity: 1,
        status: "available"
    },
    GUEST: {
        color: "#cdcbe8",
        labelcolor: "#cdcbe8",
        strokecolor: "#cdcbe8",
        strokewidth: 5,
        size: 200,
        opacity: 1,
        status: "available"
    }
} as const;

export const EDGE_THEMES = {
    QUEST: {
        color: "#FFFFFF33",
        animated: true,
        dasharray: "12",
        edgewidth: 4
    },
    AVAILABLE: {
        color: "#cdcbe8",
        animated: true,
        dasharray: "18",
        edgewidth: 4
    },
    COMPLETED: {
        color: "#2fdf75",
        animated: false,
        dasharray: "0",
        edgewidth: 6
    },
    UNAVAILABLE: {
        color: "#cdcbe8",
        animated: false,
        dasharray: "0",
        edgewidth: 3
    }
} as const;
